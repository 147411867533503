<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div style="display: flex; flex-wrap: nowrap;">
          <div>
            <div class="document-header-row px-0 pt-2 pb-2">
              <div style="flex: 0 0 170px; margin-right: 16px">
                <date_input v-model="date" label="Дата: " :time="true" required :disabled="false"
                            @userChange="onDateChange" id="date"/>
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px">
                <string_input v-model="number" label="Номер: " :disabled="true"/>
              </div>
              <div style="flex: 0 0 100px; margin-right: 16px">
                <select_input
                    v-model="currency_id"
                    :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                    :computed-getter="'get_currency_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    label="Валюта: "
                    :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                    @selectChange="onDateChange"
                />
              </div>
              <div style="flex: 0 0 256px; margin-right: 16px">
                <select_input
                    v-model="warehouse_id"
                    :computed-action="'SELECT_WAREHOUSE_LIST_ELEMENTS'"
                    :computed-getter="'get_warehouse_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Склад"
                    :show-select-on-focus="false"
                    :sync-action-props="true"
                    :select-button-object="{
                                name: 'WAREHOUSE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список складів',
                                element_title: 'Склад',
                                show: true,
                                sync_action_props: true
                              }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Товари
                </v-tab>
                <v-tab>
                  Друк
                </v-tab>
                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="above-command-panel mt-2" style="height: 76px">
                    <div style="width: 100%; height: 97%; background-color: white" class="pt-0 pb-4 px-3 mb-2 d-flex flex-nowrap">
                      <div style="width: 280px; margin-top: 12px; margin-right: 12px">
                        <select_input
                            v-model="table_chart_of_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            :show-search-on-focus="true"
                            label="Рахунок витрат"
                            :label_style="'color: var(--v-success-base); font-weight: 500;'"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true,
                              clearable: true
                            }"
                            :required="false"
                            @selectChange="afterHeaderPayChartAccountChange"
                            :clearable="true"
                        />
                      </div>
                      <div style="width: 280px; margin-top: 12px;  margin-right: 12px" v-if="table_account_detail_1_type">
                        <select_input
                            v-model="table_account_detail_1_value"
                            :computed-action="subconto_type_select_setting[table_account_detail_1_type].computed_action"
                            :computed-getter="subconto_type_select_setting[table_account_detail_1_type].computed_getter"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            label="Субконто 1"
                            :label_style="'color: var(--v-success-base); font-weight: 500;'"
                            :required="true"
                            :clearable="true"
                            :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                            @selectChange="afterHeaderDetail1Change"
                        />
                      </div>
                      <div style="width: 280px; margin-top: 12px;  margin-right: 12px" v-if="table_account_detail_2_type">
                        <select_input
                            v-model="table_account_detail_2_value"
                            :computed-action="subconto_type_select_setting[table_account_detail_2_type].computed_action"
                            :computed-getter="subconto_type_select_setting[table_account_detail_2_type].computed_getter"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            label="Субконто 2"
                            :label_style="'color: var(--v-success-base)'"
                            :required="true"
                            :clearable="true"
                            :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                            @selectChange="afterHeaderDetail2Change"
                        />
                      </div>
                      <div style="width: 280px; margin-top: 12px;" v-if="table_account_detail_3_type">
                        <select_input
                            v-model="table_account_detail_3_value"
                            :computed-action="subconto_type_select_setting[table_account_detail_3_type].computed_action"
                            :computed-getter="subconto_type_select_setting[table_account_detail_3_type].computed_getter"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            label="Субконто 3"
                            :label_style="'color: var(--v-success-base); font-weight: 500;'"
                            :required="true"
                            :clearable="true"
                            :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                            @selectChange="afterHeaderDetail3Change"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addServiceTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2" style="height: calc(100% - 120px)">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Од. виміру
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Кількість
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px">
                            Рахунок обліку
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px">
                            Рахунок витрат
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_1">
                            Субконто 1
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_2">
                            Субконто 2
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_3">
                            Субконто 3
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in services_table"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ services_table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteServiceTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                              :computed-getter="'get_nomenclature_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              :action-props="{is_service: false, warehouse_id: warehouse_id, date: date}"
                              small
                              transparent
                              :show-additional-field="true"
                              :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false, warehouse_id: warehouse_id, date: date},
                                show: true,
                                showAdditionalField: true
                              }"
                              :required="true"
                              @selectChange="afterNomenclatureSelect"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.unit_type_id"
                              :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                              :computed-getter="'get_unit_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :item-text="'additional_field_1'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="true"
                                        @userChange="serviceTableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px">
                          <select_input
                              v-model="charge.chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px">
                          <select_input
                              v-model="charge.debit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterPayChartAccountChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_1">
                          <select_input
                              v-model="charge.account_detail_1_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_1_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_1_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_2">
                          <select_input
                              v-if="charge.account_detail_2_type"
                              v-model="charge.account_detail_2_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_2_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_2_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_3">
                          <select_input
                              v-if="charge.account_detail_3_type"
                              v-model="charge.account_detail_3_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_3_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_3_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 440px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services_table.count | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_1">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_2">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_3">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%">
                  <div class="above-command-panel mt-2">
                    <div style="width: 100%; height: 97%; background-color: white"
                         class="pt-0 pb-4 px-3 mb-2 d-flex flex-nowrap">
                      <div style="width: 100%; margin-top: 12px; margin-right: 12px">
                        <document_text_area_input label="Підстава" :required="false" v-model="reason"/>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <document_print v-model="show_print"
                        :doc_type="'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME'"
                        :doc_id="itemId"
                        :doc_form="'v1'"
        ></document_print>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
          <v-icon color="teal">mdi-printer</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
  UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
  REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import warehouseInventoryOutcomeAPI from "@/utils/axios/accounting/documents/warehouse_inventory_outcome"
import {maxBy} from "lodash";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {format_date_from_python} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import {subconto_type_select_setting} from '@/utils/accounting'

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'warehouse_inventory_outcome_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_WarehouseInventoryOutcome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    document_text_area_input: () => import("@/components/accounting/input/document_text_area_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper"),
  },
  data() {
    return {
      subconto_type_select_setting,
      show_detail_1: false,
      show_detail_2: false,
      show_detail_3: false,
      show_tax_dialog: false,
      warehouse_id: this.item.warehouse_id || null,
      footer_total: {
        services_table: {count: 0, sum: 0, tax: 0, result: 0},
      },
      table_chart_of_account_id: this.item.table_chart_of_account_id || null,
      table_account_detail_1_type: this.item.table_account_detail_1_type || null,
      table_account_detail_1_value: this.item.table_account_detail_1_value || null,
      table_account_detail_2_type: this.item.table_account_detail_2_type || null,
      table_account_detail_2_value: this.item.table_account_detail_2_value || null,
      table_account_detail_3_type: this.item.table_account_detail_3_type || null,
      table_account_detail_3_value: this.item.table_account_detail_3_value || null,
      currency_id: this.item.currency_id || null,
      reason: this.item.reason || null,
      total_sum: 0,
      services_table: [],
    }
  },
  methods: {
    getDetailsShow() {
      this.show_detail_1 = !!this.services_table.filter(item => item.account_detail_1_type).length
      this.show_detail_2 = !!this.services_table.filter(item => item.account_detail_2_type).length
      this.show_detail_3 = !!this.services_table.filter(item => item.account_detail_3_type).length
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    onDateChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    getTotals() {
      this.footer_total.services_table = this.services_table.reduce((acc, item) => {
        acc.count += item.count || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    getStartInfo() {
      this.getChartsOfAccounts()
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта документа'},
        'warehouse_id': {id: 'warehouse_id', text: 'Не заповнений склад'},
      }

      if (this.table_chart_of_account_id) {
        header['table_chart_of_account_id'] = {id: 'table_chart_of_account_id', text: 'Не заповнений рахунок витрат'}
      }

      if (this.table_account_detail_1_type) {
        header['table_account_detail_1_value'] = {id: 'table_account_detail_1_value', text: 'Не заповнене субконто 1'}
      }
      if (this.table_account_detail_2_type) {
        header['table_account_detail_2_value'] = {id: 'table_account_detail_2_value', text: 'Не заповнене субконто 1'}
      }
      if (this.table_account_detail_3_type) {
        header['table_account_detail_3_value'] = {id: 'table_account_detail_3_value', text: 'Не заповнене субконто 1'}
      }

      const table_charge = {
        'nomenclature_id': {id: 'nomenclature_id', table: 'Товари', text: 'Не заповнена номенклатура'},
        'chart_of_account_id': {id: 'chart_of_account_id', table: 'Товари', text: 'Не заповнений рахунок доходу'},
        'debit_chart_of_account_id': {id: 'debit_chart_of_account_id', table: 'Товари', text: 'Не заповнений рахунок витрат'},
        'count': {id: 'count', table: 'Товари', text: 'Не заповнена кількість'},
        'account_detail_1_value': {
          condition: 'account_detail_1_type',
          text: 'Не заповнене субконто 1'
        },
        'account_detail_2_value': {
          condition: 'account_detail_2_type',
          text: 'Не заповнене субконто 2'
        },
        'account_detail_3_value': {
          condition: 'account_detail_3_type',
          text: 'Не заповнене субконто 3'
        },
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(table_charge, 'Товари', 'services_table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.currency_id = local_data.currency_id || null
      this.warehouse_id = local_data.warehouse_id || null
      this.table_chart_of_account_id = local_data.table_chart_of_account_id || null
      this.table_account_detail_1_type = local_data.table_account_detail_1_type || null
      this.table_account_detail_1_value = local_data.table_account_detail_1_value || null
      this.table_account_detail_2_type = local_data.table_account_detail_2_type || null
      this.table_account_detail_2_value = local_data.table_account_detail_2_value || null
      this.table_account_detail_3_type = local_data.table_account_detail_3_type || null
      this.table_account_detail_3_value = local_data.table_account_detail_3_value || null
      this.reason = local_data.reason || null
      this.itemId = payload.id || null

      this.services_table = []
    },
    clearDocument() {
      this.services_table = []
      this.getTotals()
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },

    addServiceTableRow() {
      const charge_703_id = '92'
      const charge_201_id = '201'
      const chart_703 = this.charts.find(c => c.code === charge_703_id) || {}
      const chart_201 = this.charts.find(c => c.code === charge_201_id) || {}


      let details = {}

      if (!this.table_chart_of_account_id) {
        details = this.get_account_details(chart_703.id)
      }

      this.services_table.push(
          {
            nomenclature_id: null,
            count: 1,
            unit_type_id: null,
            chart_of_account_id: chart_201.id,
            debit_chart_of_account_id: this.table_chart_of_account_id
                ? this.table_chart_of_account_id
                : chart_703.id,
            account_detail_1_value: this.table_account_detail_1_value,
            account_detail_1_type: this.table_chart_of_account_id
                ? this.table_account_detail_1_type
                : details.account_detail_1.type,
            account_detail_2_value: this.table_account_detail_2_value,
            account_detail_2_type: this.table_chart_of_account_id
                ? this.table_account_detail_2_type
                : details.account_detail_2.type,
            account_detail_3_value: this.table_account_detail_3_value,
            account_detail_3_type: this.table_chart_of_account_id
                ? this.table_account_detail_3_type
                : details.account_detail_3.type,
            row_num: this.getNewRowNum('services_table'),
          }
      )
      this.getDetailsShow()
    },
    deleteServiceTableRow(payload) {
      this.services_table.splice(this.services_table.indexOf(payload), 1)
      this.getTotals()
      this.getDetailsShow()
    },
    serviceTableColChange(payload) {
      const row = this.services_table.find(i => i.row_num === payload.row_num)
      if (!row) return

      this.getTotals()
    },
    afterNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service, unit_type_id
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
        unit_type_id = +(val.additional_field_2) || null
      } else {
        pdv = val.tax_type
        is_service = val.is_service
        unit_type_id = val.unit_type
      }

      if (val.row_num) {
        const row = this.services_table.find(item => item.row_num === val.row_num)
        if (row) {
          if (is_service) {
            this.$nextTick(() => {
              row.nomenclature_id = null
            })
            return;
          }
          row.tax_id = pdv
          row.unit_type_id = unit_type_id
          this.serviceTableColChange(val)
        }
      }
    },
    afterPayChartAccountChange(payload) {
      const row = this.services_table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.account_detail_1_type = details.account_detail_1.type
        row.account_detail_1_value = null
        row.account_detail_2_type = details.account_detail_2.type
        row.account_detail_2_value = null
        row.account_detail_3_type = details.account_detail_3.type
        row.account_detail_3_value = null
      }
      this.getDetailsShow()
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderPayChartAccountChange(payload) {
      let details = {}
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
        details = this.get_account_details(payload.value)
      } else {
        value = payload.id
        details = this.get_account_details(payload.id)
      }
      this.table_account_detail_1_type = details.account_detail_1.type
      this.table_account_detail_1_value = null
      this.table_account_detail_2_type = details.account_detail_2.type
      this.table_account_detail_2_value = null
      this.table_account_detail_3_type = details.account_detail_3.type
      this.table_account_detail_3_value = null

      this.services_table.forEach(item => {
        item.debit_chart_of_account_id = value
        item.account_detail_1_type = this.table_account_detail_1_type
        item.account_detail_2_type = this.table_account_detail_2_type
        item.account_detail_3_type = this.table_account_detail_3_type
        item.account_detail_1_value = this.table_account_detail_1_value
        item.account_detail_2_value = this.table_account_detail_2_value
        item.account_detail_3_value = this.table_account_detail_3_value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail1Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_1_type = this.table_account_detail_1_type
        item.account_detail_1_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail2Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_2_type = this.table_account_detail_2_type
        item.account_detail_2_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail3Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_3_type = this.table_account_detail_3_type
        item.account_detail_3_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },

    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        date: this.date,
        currency_id: this.currency_id,
        warehouse_id: this.warehouse_id || null,
        table_chart_of_account_id: this.table_chart_of_account_id,
        table_account_detail_1_type: this.table_account_detail_1_type,
        table_account_detail_2_type: this.table_account_detail_2_type,
        table_account_detail_3_type: this.table_account_detail_3_type,
        table_account_detail_1_value: this.table_account_detail_1_value,
        table_account_detail_2_value: this.table_account_detail_2_value,
        table_account_detail_3_value: this.table_account_detail_3_value,
        reason: this.reason,
        goods: this.services_table
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },
    fetch_data_by_id() {
      if (this.isNew) return
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
      warehouseInventoryOutcomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.tab = 0
            this.date = data.date
            this.number = data.number
            this.conducted = data.conducted
            this.currency_id = data.currency_id
            this.warehouse_id = data.warehouse_id
            this.table_chart_of_account_id = data.table_chart_of_account_id
            this.table_account_detail_1_type = data.table_account_detail_1_type
            this.table_account_detail_1_value = data.table_account_detail_1_value
            this.table_account_detail_2_type = data.table_account_detail_2_type
            this.table_account_detail_2_value = data.table_account_detail_2_value
            this.table_account_detail_3_type = data.table_account_detail_3_type
            this.table_account_detail_3_value = data.table_account_detail_3_value
            this.reason = data.reason

            this.services_table = data.goods.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getTotals()
          })
          .finally(() => {
            this.getDetailsShow()
          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа списання товарів № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearDialog() {
      const payload = {
        text: `Очистити таблицю з товарами?`,
        accept_button: true,
        id: 'clear_service_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
                  .finally(() => {
                    this.getStartInfo()
                    this.checkDocument(false)
                  })
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_service_table') {
                if (payload.answer) {
                  this.clearDocument()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),
    footer_total_width() {
      if (this.show_detail_1 && this.show_detail_2 && this.show_detail_3) {
        return 940
      }
      if (this.show_detail_1 && this.show_detail_2 && !this.show_detail_3) {
        return 790
      }
      if (this.show_detail_1 && !this.show_detail_2 && !this.show_detail_3) {
        return 640
      }
      return 490
    },
    document_header_ready() {
      return this.document_state.header_ready
    }
  }
}
</script>

<style scoped lang="scss">

</style>